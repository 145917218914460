<template lang="pug">
    v-row.pt-15
        v-col.pr-2.pt-15(cols='6')
            v-btn(:ripple='false' @click='one' width='100%' height='40' color='cancel' depressed dark) {{"List"}}
            v-btn(:ripple='false' @click='two' width='100%' height='40' color='success' depressed dark) {{"家奔"}}
            v-btn(:ripple='false' @click='three' width='100%' height='40' color='success' depressed dark) {{"NEW0"}}
            v-btn(:ripple='false' @click='ZXC' width='100%' height='40' color='success' depressed dark) {{"NEW0"}}
            v-btn(:ripple='false' @click='FFF' width='100%' height='40' color='success' depressed dark) {{"建新的"}}
            v-btn(:ripple='false' @click='WONG' width='100%' height='40' color='success' depressed dark) {{"王捷利"}}
            vue-tabs-chrome( ref="tab" :minHiddenWidth="120" v-model="tab" :tabs="tabs" @contextmenu="handleClick")
</template>

<script>
    import Vue from 'vue';
    // import NoteDataService from "@/services/NoteDataService";
    // import i18n from '/common/plugins/vue-i18n.js' 
    import VueTabsChrome from 'vue-tabs-chrome'

    export default Vue.extend({
        props: {
        },
         components: {
        VueTabsChrome
        },
    created(){

    },
        data() {
            return {
                valid: true,
                tab: 'google',
                tabs: [
                {
                    label: 'google',
                    key: 'google',
                    closable: false,
                    // favicon: require('./assets/google.jpg')
                },
                {
                    label: 'facebook',
                    key: 'facebook',
                    // favicon: require('./assets/fb.jpg')
                },
                {
                    label: 'New Tab',
                    key: 'any-string-key',
                }
                ]
            }
        },
        methods: {
            one(){
                const eventData = 
                { 
                    event: 'appContext',
                    contact: 
                    {
                        "additional_attributes":
                        {
                            "description":"",
                            "company_name":"",
                            "social_profiles":
                            {
                                "github":"",
                                "twitter":"",
                                "facebook":"",
                                "linkedin":""
                            }
                        },
                        "email":"arnoldng@yahoo.com",
                        "id":1,
                        "name":"1231",
                        "phone_number":"",
                        "identifier":null,
                        "thumbnail":"",
                        "custom_attributes":{},
                        "last_activity_at":1661982471
                    }
                };
                window.parent.postMessage(JSON.stringify(eventData), '*');
            },
            two(){
                const eventData = 
                { 
                    event: 'appContext',
                    contact:
                    {
                        "additional_attributes":
                        {
                            "description":"",
                            "company_name":"",
                            "social_profiles":
                            {
                                "github":"",
                                "twitter":"",
                                "facebook":"",
                                "linkedin":""
                            }
                        },
                        "email":"",
                        "id":3,
                        "name":"家 奔",
                        "phone_number":"",
                        "identifier":null,
                        "thumbnail":"",
                        "custom_attributes":{},
                        "last_activity_at":1661982471
                    }
                };
                window.parent.postMessage(JSON.stringify(eventData), '*');
            },
            three(){
                const eventData = 
                { 
                    event: 'appContext',
                    contact:
                    {
                        "additional_attributes":
                        {
                            "description":"",
                            "company_name":"",
                            "social_profiles":
                            {
                                "github":"",
                                "twitter":"",
                                "facebook":"",
                                "linkedin":""
                            }
                        },
                        "email":"",
                        "id":4,
                        "name":"NEW0",
                        "phone_number":"",
                        "identifier":null,
                        "thumbnail":"",
                        "custom_attributes":{},
                        "last_activity_at":1661982471
                    }
                };
                window.parent.postMessage(JSON.stringify(eventData), '*');
            },
            ZXC(){
                const eventData = 
                { 
                    event: 'appContext',
                    contact: 
                    {
                        "additional_attributes":
                        {
                            "description":"",
                            "company_name":"",
                            "social_profiles":
                            {
                                "github":"",
                                "twitter":"",
                                "facebook":"",
                                "linkedin":""
                            }
                        },
                        "email":"",
                        "id":4,
                        "name":"NEW0",
                        "phone_number":"",
                        "identifier":null,
                        "thumbnail":"",
                        "custom_attributes":{},
                        "last_activity_at":1661982471
                    }
                };
                window.parent.postMessage(JSON.stringify(eventData), '*');
            },
            FFF(){
                const eventData = 
                { 
                    event: 'appContext',
                    contact: 
                    {
                        "additional_attributes":
                        {
                            "description":"123456",
                            "company_name":"不安安公司",
                            "social_profiles":
                            {
                                "github":"",
                                "twitter":"",
                                "facebook":"https://www.msn.com/",
                                "linkedin":"https://www.msn.com/"
                            }
                        },
                        "email":"",
                        "id":5,
                        "name":"新的新的ㄏㄏ",
                        "phone_number":"",
                        "identifier":null,
                        "thumbnail":"",
                        "custom_attributes":{},
                        "last_activity_at":1661982471
                    }
                };
                window.parent.postMessage(JSON.stringify(eventData), '*');
            },
            WONG(){
                const eventData = 
                { 
                    event: 'appContext',
                    contact: 
                    {
                        "additional_attributes":
                        {
                            "description":"123",
                            "company_name":"",
                            "social_profiles":
                            {
                                "github":"",
                                "twitter":"",
                                "facebook":"https://www.msn.com/",
                                "linkedin":"https://www.msn.com/"
                            }
                        },
                        "email":"jerry2@gmail.com",
                        "id":249,
                        "name":"王捷利",
                        "phone_number":"+886900000001",
                        "identifier":null,
                        "thumbnail":"",
                        "custom_attributes":{},
                        "last_activity_at":1661982471
                    }
                };
                window.parent.postMessage(JSON.stringify(eventData), '*');
            },
            addTab () {
                let item = 'tab' + Date.now()
                let newTabs = [
                    {
                    label: 'New Tab',
                    key: item
                    }
                ]
                this.$refs.tab.addTab(...newTabs)
                this.tab = item
            },
            removeTab () {
                this.$refs.tab.removeTab(this.tab)
            },
            handleClick (e, tab, i) {
                console.log(e, tab, i)
            }
        }
    });
</script>
