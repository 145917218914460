<template lang="pug">
        div(class="chrome-ui" ref="content")
            div(v-if='tabs.length == 0' class='item-center')
                v-img(src="@/assets/images/conversation.png" max-width="320" )
                h5.ma-0.t-black {{$t('UPCHAT.BLANK')}}
            vue-tabs-chrome( ref="tab" :minHiddenWidth="120"  theme="custom" v-model="tab" :tabs="tabs" @contextmenu="handleClick" v-show='tabs.length > 0' @remove="handleRemove")
            iframe(v-for='item in tabs' :src="item.url" width="100%" height="100vh" frameborder="0" v-show='tab == item.key' :id='"tab_"+item.key' @load='load_iframe()')
            v-dialog(v-model='upchatContactDialog' width='1050' conStent-class='contact-dialog' v-if='upchatContactDialog')
                upchat-contact(@emitupchatContactDialog='onEmitupchatContactDialog' :upchat-contact='upchatContactList_data' :contact-data='upchatContactList')
</template>

<script>
    import Vue from 'vue';
    import ContactDataService from "@/services/ContactDataService";
    import i18n from '/common/plugins/vue-i18n.js'; 
    import VueTabsChrome from 'vue-tabs-chrome';
    import upchatContact from '@/components/UpChat/upchatContactDialog.vue';
    import messageSheet from '@/components/Common/messageSheet.vue';
    import OrganizationDataService from "@/services/OrganizationDataService";

    export default Vue.extend({
        props: {
        },
         components: {
            upchatContact,
            VueTabsChrome,
            messageSheet
        },
        mounted () {
            this.tab = 'bing';
        },
        async created(){
            await this.addListener();
            await (this.first_run = true);
        },
        data() {
            return {
                upchatContactDialog:false,
                upchatContactDialog_id:null,
                contact_list : [],
                upchatContactList:[],
                upchatContactList_data:null,
                contact_data:null,
                first_run:false,
                valid: true,
                tab: null,
                tabs: [],
                organizationList:[],
                org_data:{},
                tabs_check: new Set(),
            }
        },
        methods: {
            load_iframe(){
                let tab = this.getCurrTab();
                if(tab == undefined){
                    return;
                }
                let location = tab.url || '';
                if (!location) {
                    return '';
                }
                if (!location.startsWith('http://') && !location.startsWith('https://')) {
                    location = 'https://' + location;
                }
                this.location = location;
                this.delete_menubar(tab.key);
            },
            delete_menubar(id){
                let time_id = setInterval(()=>{
                    if(this.tabs.length == 0){
                        clearInterval(time_id)
                    }else{
                        let tab_document = document.getElementById('tab_'+id).contentWindow.document;
                        if(tab_document && tab_document.getElementById('menu-bar')){
                            tab_document.getElementById('menu-bar').remove();
                            if(tab_document.getElementById('notice-board')){
                                tab_document.getElementById('notice-board').remove();
                            }
                            tab_document.getElementsByClassName('v-main')[0].classList.add('crm_v_main');
                            setTimeout(()=>{window.parent.postMessage('fin_contact_loading', '*');},1200)
                            clearInterval(time_id)
                        }else if(tab_document){
                            clearInterval(time_id)
                        }
                    }
                },200);
            },
            async onEmitupchatContactDialog(name,id,page,sync){
                if(name && id && page){
                    let newTabs = 
                    [
                        {
                            label: name,
                            key: this.upchatContactDialog_id,
                            url:`/${page}/`+id
                        }
                    ];
                    this.$refs.tab.addTab(...newTabs);
                    this.tab = this.upchatContactDialog_id;
                    if(page == "Contacts" && sync){
                        let upchat_id = parseInt(this.upchatContactDialog_id.replace("tab-",""));
                        let sync_data = {
                            "contact_id": id,
                            "upchat_id": upchat_id
                        }
                        ContactDataService.createUpchatContacts(sync_data);
                    }
                    this.upchatContactDialog = false;
                    this.upchatContactDialog_id = null;
                }
                else{
                    let newTabs = 
                    [
                        {
                            label: i18n.t('CONTACT.TITLE'),
                            key: this.upchatContactDialog_id,
                            url:'/Contacts'
                        }
                    ];
                    this.$refs.tab.addTab(...newTabs);
                    this.tab = this.upchatContactDialog_id;
                    this.upchatContactDialog = false;
                    this.upchatContactDialog_id = null;
                }
            },
            addListener(){
                window.addEventListener("message", this.listenerFunc, false);
            },
            async listenerFunc(event){
                if(typeof(event.data)=='object')
                {
                    if(event.data.new_tab_name){
                        let tab = this.getCurrTab()
                        tab.label = event.data.new_tab_name
                    }
                    return;
                }
                if(this.upchatContactDialog){
                    this.upchatContactDialog = false;
                    this.upchatContactDialog_id = null;
                }
                if (event.data !== 'chatlist-loaded') {
                    var data = JSON.parse(event.data);
                    var contact = JSON.parse(data.contact);
                    // var contact = data.contact;
                    // check additional_attributes
                    let attributes = contact.additional_attributes
                    let attributes_list = Object.keys(attributes)
                    if(attributes_list.length == 0){
                        contact.additional_attributes.description = ''
                        contact.additional_attributes.company_name = ''
                        contact.additional_attributes.social_profiles = {
                            "github": "",
                            "twitter": "",
                            "facebook": "",
                            "linkedin": ""
                        }
                    }
                    else{
                        if(!attributes_list.includes("description")){
                            contact.additional_attributes.description = ''
                        }
                        if(!attributes_list.includes("company_name")){
                            contact.additional_attributes.company_name = ''
                        }
                        if(!attributes_list.includes("social_profiles")){
                            contact.additional_attributes.social_profiles = {"github": "","twitter": "","facebook": "","linkedin": ""}
                        }else{
                            let social_profiles_list = Object.keys(attributes.social_profiles)
                            if(!social_profiles_list.includes("facebook")){
                                contact.additional_attributes.social_profiles.facebook = ''
                            }
                            if(!social_profiles_list.includes("linkedin")){
                                contact.additional_attributes.social_profiles.linkedin = ''
                            }
                        }
                    }
                    let tab_id = 'tab-' + contact.id;
                    this.contact_data = null;
                    this.contact_list = [];
                    this.organizationList = [];
                    this.org_data ={};
                    //confirm duplicate tab_id
                    if(this.tabs_check.has(tab_id)){
                        this.tab = tab_id;
                        window.parent.postMessage('fin_contact_loading', '*');
                        return;
                    }else{
                        this.tabs_check.add(tab_id);
                    }

                    let search_cond = {"full_name":contact.name,"email":contact.email,"mobile_phone":contact.phone_number}
                    await this.get_contact_list(search_cond,contact.id);
                    let newTabs = [
                        {
                            label: 'New Tab',
                            key: tab_id
                        }
                    ] 
                    if(this.contact_data){
                        newTabs = [
                            {
                                label: this.contact_data.full_name_en,
                                key: tab_id,
                                url:'/Contacts/'+this.contact_data.id
                            }
                        ];
                        this.$refs.tab.addTab(...newTabs);
                        this.tab = tab_id;
                        this.contact_data = null;
                        return;
                    }
                    else if(this.contact_list.list.length>=1){
                        // await window.localStorage.setItem('UpChatList',JSON.stringify(this.contact_list))
                        this.upchatContactList = this.contact_list.list;
                        this.upchatContactDialog_id = tab_id;
                        this.upchatContactList_data = contact;
                        this.upchatContactDialog = true;
                        window.parent.postMessage('fin_contact_loading', '*');
                        return;
                    }
                    else if(this.contact_list.list.length == 0){
                        this.upchatContactDialog_id = tab_id;
                        if(contact.additional_attributes.company_name != ''){
                            await this.get_org(contact.additional_attributes.company_name);
                        }
                        
                        const nameArray = contact.name.split(" ");
                        let first_name = null;
                        let last_name = null;
                        if(nameArray.length>1){
                            first_name = nameArray.slice(0,nameArray.length - 1).join(" ");
                            last_name = nameArray[nameArray.length - 1];
                        }else{
                            first_name = null;
                            last_name = contact.name;
                        }
                        let facebook = contact.additional_attributes.social_profiles.facebook;
                        let linkedin = contact.additional_attributes.social_profiles.linkedin;
                        if(facebook !=''){
                            facebook = !facebook.includes("https://facebook.com/") ? "https://facebook.com/" + facebook : facebook ;
                        }
                        if(linkedin !=''){
                            linkedin = !linkedin.includes("https://linkedin.com/") ? "https://linkedin.com/" + linkedin : linkedin ;
                        }
                        let addContact = {
                            "custom_field" : null,
                            "email": contact.email != ''? contact.email:null,
                            "first_name": first_name,
                            "instagram_id": null,
                            "id":null,
                            "last_name": last_name,
                            "level": null,
                            "line_id": null,
                            "facebook":facebook !='' ? facebook : null,
                            "linkedin":linkedin !='' ? linkedin : null,
                            "mobile_phone": contact.phone_number != ''? contact.phone_number:null,
                            "office_phone": null,
                            "organization_set":Object.keys(this.org_data).length > 0? [this.org_data] : [],
                            "description": contact.additional_attributes.description !=''? contact.additional_attributes.description:null,
                        }

                    await ContactDataService.create(addContact,contact.id)
                        .then(response => {
                            let full_name = response.data.first_name? response.data.last_name +" "+ response.data.first_name:response.data.last_name
                            this.onEmitupchatContactDialog(full_name,response.data.id,'Contacts')
                        })
                    }
                }
            },
            hideAvatar(){
                let frameEle = document.getElementsByClassName("CRM_frame");
                if(frameEle.length != 0){
                    let isCRM = 'hide Avatar';
                    for(let i = 0; i<frameEle.length; i++){
                        frameEle[i].contentWindow.postMessage(isCRM, '*');
                    }
                }
            },
            async get_contact_list(data,id){
                this.contact_list = [];
                this.contact = null;
                await ContactDataService.getUpchatContacts(id).then((response)=>{
                    this.contact_list = response.data;
                    if (this.contact_list.length == 1){
                        this.contact_data = this.contact_list[0];
                        if(this.contact_data.first_name){
                            this.contact_data.full_name_en = this.contact_data.first_name+" "+this.contact_data.last_name;
                        }else{
                            this.contact_data.full_name_en = this.contact_data.last_name;
                        }
                        
                    }
                })
                if(this.contact_list.length == 0){
                    await ContactDataService.getAll(1,"",100,[],"",JSON.stringify(data).replaceAll("&","%26")).then((response)=>{
                        this.contact_list = response.data;
                    })
                }
            },
            async get_org(name){
                await OrganizationDataService.findOrganizationByNamefromDB(name)
                .then((response) => {
                    this.organizationList = response.data;
                    if(this.organizationList.length>0){
                    this.org_data={
                        "company_number":this.organizationList[0].company_number,
                        "id":this.organizationList[0].id,
                        "name":name
                    }
                    }
                    if(this.organizationList.length==0){
                        this.org_data={
                            "company_number":"0",
                            "id": "-1",
                            "name":name
                        }
                    }
                })
            },
        addTab () {
            let item = 'tab' + Date.now();
            let newTabs = [
                {
                    label: 'New Tab',
                    key: item
                }
            ]
            this.$refs.tab.addTab(...newTabs);
            this.tab = item;
            this.location = '';
        },
        removeTab () {
            this.$refs.tab.removeTab(this.tab);
        },
        handleClick (e, tab, i) {
            console.log(e, tab, i);
        },
        // handleSearch () {
        //     let { location } = this
        //     let label = capitalize(location.split('.').slice(-2)[0])
        //     let favicon = ''
        //     if (location.includes('.')) {
        //         if (!location.startsWith('http://') && !location.startsWith('https://')) {
        //         location = 'https://' + location
        //         }
        //     } else {
        //         location = `https://www.bing.com/search?q=${encodeURIComponent(location)}`
        //         label = 'Bing'
        //         favicon = 'https://bing.com/favicon.ico'
        //     }
        //     if (!favicon) {
        //         favicon = location + '/favicon.ico'
        //     }
        //     let tab = this.getCurrTab()
        //     tab.label = label
        //     tab.url = location
        //     tab.favicon = favicon
        //     // this.tab = key
        //     this.$refs.location.blur()
        //     this.tabs.splice()
        // },
        getCurrTab () {
            return this.tabs.find(item => item.key === this.tab)
        },
        handleNavClick (msg) {
            alert(msg)
        },
        handleCollection () {
            alert('😆')
        },
        handleMore () {
            alert('🤗')
        },
        handleFullscreen () {
            this.$refs.content.requestFullscreen()
        },
        handleRemove (tab) {
            this.tabs_check.delete(tab.key);
        },
        },
        computed: {
            url () {
                let currTab = this.tabs.find(item => item.key === this.tab);
                let url = '';
                if (currTab) {
                    url = currTab.url || '';
                }
                if (!url.startsWith('http://') && !url.startsWith('https://')) {
                    url = 'https://' + url;
                }
                return url;
            }
        },
        watch: {
            tab (){
                this.load_iframe()
            },
            upchatContactDialog (){
                if(!this.upchatContactDialog && this.tabs_check.size > this.tabs.length ){
                    let tabs_id = this.tabs.map(el=>el.key);
                    [...this.tabs_check].reduce((accumulator, currentValue)=>{
                        if(!tabs_id.includes(currentValue)){
                            this.tabs_check.delete(currentValue);
                        }
                    },[]
                    );
                }
            },
            tabs (){
                if (this.tabs_check.size < this.tabs.length ){
                    let tabs_id = this.tabs.map(el=>el.key);
                    tabs_id.reduce((accumulator, currentValue)=>{
                        if(![...this.tabs_check].includes(currentValue)){
                            this.tabs_check.add(currentValue);
                        }
                    },[]
                    );
                }
            },
        },
    });
</script>

<style lang="scss">
.item-center{
    display:inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // height: 100vh;
    width: 100vw;
    padding-top: 35vh;

}
.vue-tabs-chrome.theme-custom {
  padding-top: 0;
  background-color: transparent;
  overflow: hidden;
  .tabs-footer,
  .tabs-divider,
  .tabs-background-before,
  .tabs-background-after {
    display: none;
  }
  .tabs-item {
    cursor: pointer;
  }
  .tabs-content {
    overflow: unset;
    border-bottom: 0px solid #ffffff;
  }
  .tabs-background {
    padding: 0;
  }
  .tabs-background-content {
    border-top: 1px solid #e4e7ed;
    border-left: 1px solid #e4e7ed;
    border-right: 1px solid #e4e7ed;
    border-radius: 0 15px 0 0px;
    background-color: rgb(221, 221, 221);
  }
  .tabs-content {
    height: 40px;
  }
  .active {
    color: #409eff;
    .tabs-background {
        border-top: 4px solid #50abff;
        border-radius: 0 15px 0 0px;
      &::before,
      &::after {
        top: 100%;
        left: 0;
        content: '';
        width: 100%;
        height: 1px;
        background-color: #fff;
        z-index: 1;
        position: absolute;
      }
      &::before {
        top: 0;
        height: 0px;
        background-color: #409eff;
      }
    }
  }
}
</style>


