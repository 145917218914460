// import CustomViewDataService from "@/services/CustomViewDataService";
import PersonalSettingService from "@/services/PersonalSettingService";

let headerData;

export default {
  install(Vue) {
    Vue.directive('resize-table', {
      inserted(el,binding,vnode) {
        if(!headerData || headerData.page != binding.value.page || headerData.id != binding.value.id){
            headerData = JSON.parse(JSON.stringify(binding.value));
            headerData.width = {};
            let count = 0;
            headerData.value.forEach(el=>{
                if(!el.width && !(headerData.customWidth&&headerData.customWidth[el.value])){
                    count ++;
                }
            })
            headerData.value.forEach(el=>{
                if(headerData.customWidth&&headerData.customWidth[el.value]){
                    headerData.width[el.value] = headerData.customWidth[el.value];
                }else if(el.width){
                    headerData.width[el.value] = el.width;
                }else{
                    if(count>=8){
                        headerData.width[el.value] = "200px";
                    }else{
                        headerData.width[el.value] = null;
                    }
                }
            })
        }

        let nodeName = el.nodeName;

        if (['TABLE', 'THEAD'].indexOf(nodeName) < 0) {
            el = el.querySelector('table'); // looking for the closest table tag
            if (!el) return;
            nodeName = 'TABLE';
        }

        const table = nodeName === 'TABLE' ? el : el.parentElement;
        const thead = table.querySelector('thead');
        const ths = thead.querySelectorAll('th');
        const barHeight = nodeName === 'TABLE' ? table.offsetHeight : thead.offsetHeight;

        // create a container for the resize bars
        const resizeContainer = document.createElement('div');
        table.style.position = 'relative';
        resizeContainer.style.position = 'relative';
        resizeContainer.style.width = table.offsetWidth + 'px';
        resizeContainer.className = "vue-columns-resizable";
        table.parentElement.insertBefore(resizeContainer, table);
        table.style.overflowX = "scroll";
        table.style.minWidth = "100%";

        let moving = false;
        let movingIndex = 0;
        var pageX,curColWidth;

        ths.forEach((th, index) => {
            // 設定寬度
            if(!headerData.width[headerData.value[index].value]){
                headerData.width[headerData.value[index].value] = th.offsetWidth + "px";
                headerData.customWidth[headerData.value[index].value] = th.offsetWidth + "px";
            }
            th.style.width = headerData.width[headerData.value[index].value];
            headerData.value[index]['width'] = th.offsetWidth + "px";
          // set a attribute in html to store columns width
          th.setAttribute('tbWidth',th.offsetWidth + 'px')

          if (index + 1 >= ths.length) return;

          const nextTh = ths[index + 1];
          const bar = document.createElement('div');

          bar.style.position = 'absolute';
          bar.style.left = nextTh.offsetLeft - 4 + 'px';
          bar.style.top = 0;
          bar.style.height = barHeight + 'px';
          bar.style.width = '8px';
          bar.style.cursor = 'col-resize';
          bar.style.zIndex = 1;
          bar.className = 'columns-resize-bar';
          bar.dataset.headervalue = headerData.value[index].value;
          
          bar.addEventListener('mousedown', (e) => {
            moving = true;
            movingIndex = index;
            document.body.style.cursor = 'col-resize';
            document.body.style.userSelect = 'none';
            pageX = e.pageX; 
            curColWidth = ths[index].style.width;
          });

          resizeContainer.appendChild(bar);
        });

        const bars = resizeContainer.querySelectorAll('.columns-resize-bar');
        const cutPx = str => +str.replace('px', '');

        const handleResize = e => {
          if (moving) {
            const th = ths[movingIndex];
            const nextTh = ths[movingIndex + 1];
            const bar = bars[movingIndex];
            let diffX = e.pageX - pageX;
            let newWidth = cutPx(curColWidth) + diffX;
            if(movingIndex==0 && newWidth <100){
              return;
            }
            else if(newWidth>=70){
              th.style.width = newWidth + 'px';
              nextTh.style.width = cutPx(nextTh.style.width) - e.movementX + 'px';
              bar.style.left = nextTh.offsetLeft - 4 + e.movementX + 'px';
            }
          }
        };

        resizeContainer.addEventListener('mousemove', handleResize);
        table.addEventListener('mousemove', handleResize);

        document.addEventListener('mouseup', () => {
          if (!moving) return;
          moving = false;
          document.body.style.cursor = '';
          document.body.style.userSelect = '';

          bars.forEach((bar, index) => {
            const th = ths[index];
            const nextTh = ths[index + 1];
            th.style.width = th.offsetWidth + 'px';
            bar.style.left = nextTh.offsetLeft - 4 + 'px';
            headerData.width[bar.dataset.headervalue] = th.offsetWidth + 'px';
          });
          headerData.customWidth = headerData.width;
          ths.forEach(th => {
            th.setAttribute('tbWidth',th.offsetWidth + 'px')
          })
          saveWidth();
        });

        const saveWidth = function(){
          let structure = {
            name: headerData.name,
            customer_view_id: headerData.id,
            width: headerData.width,

          }
          let data = {}
          data[headerData.page] = [structure]
          let user = window.localStorage.getItem('user');
          let userData = JSON.parse(user)
          PersonalSettingService.updateTableWidth(userData.userid, data);
        }

        // Store the widths of the columns in a new property on el
        el.allColumnWidths = Array.from(ths).map(th => th.offsetWidth)
        // vnode.context.$emit("getHeaderwithWidth",headerData.value)
        vnode.context.getHeaderwithWidth(headerData.value);
      },
      // unbind(){
      //     let structure = {
      //       name: headerData.name,
      //       customer_view_id: headerData.id,
      //       width: headerData.width,
      //     }
      //     let data = {}
      //     data[headerData.page] = [structure]
      //     console.log('========unbind api data======',data)
      //     let user = window.localStorage.getItem('user');
      //     let userData = JSON.parse(user)
      //     PersonalSettingService.updateTableWidth(userData.userid, data);
      // }
    });
  },
};