<template lang="pug">
v-card(:class='contactData.length >= 8 ? "dialog-height-small" : ""')
  v-overlay(v-show='add_new_contact' opacity='0' z-index='999')
  v-col.d-flex.align-center.pa-5.pb-0
    v-row(no-gutters)
      v-col(cols="9")
        .d-flex.align-center.height-62
          h2.my-0(style="white-space:nowrap;") {{$t('RELATEDCONTACTS.TITLE')}}
      v-col.text-right(cols="3").d-flex.align-center
          v-btn.ml-3.add-deal-btn(@click="addContact" width='120' height='32' color='green' plain border-0 :ripple="false"  depressed dark fab)
            v-icon.mr-1(v-if='!add_new_contact' size='14') icon-add
            span(v-if='!add_new_contact') {{$t('GENERAL.ADD')}}
            v-icon.mr-1(size='16' color='green' v-if='add_new_contact') fas fa-circle-notch fa-spin
            span(v-if='add_new_contact') {{$t('IMPORT_EXPORT.ING')}}
          v-btn.ml-3( @click="onCancel" width='120' height='32' color='black' plain border-0 :ripple="false" depressed dark fab)
            v-icon.mr-1(size='14') icon-cancel
            span {{$t('GENERAL.CANCEL')}}
          //- v-btn.ml-auto( :ripple="false" @click="onCancel" depressed="depressed" icon)
          //-   v-icon icon-cancel
  v-card-text.pt-4.px-5.pb-5
    .data-area
      v-data-table.border-table( :headers="mainContactHeader" :items="contactData" :items-per-page="mainContactItemPerPage" :page.sync="mainContactPage" :search="searchMainContact" multi-sort :header-props="{sortIcon: 'mdi mdi-arrow-up'}" @page-count="mainContactPageCount = $event" hide-default-footer="hide-default-footer")
        template(v-slot:item.full_name="{ item }")
            .ellipsis
                a.t-primary(@click="addTab(item.full_name,item.id,'Contacts')" ) {{ item.full_name }}
        template(v-slot:item.mobile_phone="{ item }")
            v-btn.w-100.d-block(v-if='item.mobile_phone!= null' :href='onCall(item.mobile_phone)' text color="primary" :title="item.mobile_phone") 
                .ellipsis.pt-3.w-100  
                    span.w-100  {{ item.mobile_phone }}
        template(v-slot:item.email="{ item }")
            v-btn.w-100.d-block(v-if='item.email!= null' :href="onMail(item.email)" text color="primary" :title="item.email") 
                .ellipsis.pt-3.w-100  
                    span.w-100  {{ item.email }}
        template(v-slot:item.accountName="{ item }")
            .ellipsis
                a.t-primary(@click="addTab(item.full_name,item.accountId,'Account')") {{ item.accountName }}
      v-pagination.mt-3(
        v-model="mainContactPage",
        :length="mainContactPageCount",
        circle="circle"
        :total-visible="10"
      )
  v-dialog(v-model="successDialog" width="360" content-class="statusDialog")
    success-dialog(@emitSuccessDialog="onEmitSuccessDialog")
  v-dialog(v-model="messageDialog" width="360" content-class="statusDialog")
    message-dialog(@emitMessageDialog="onEmitMessageDialog" :message="message")
  v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog()')
    error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import Vue from "vue";
import addContact from "@/components/Dialog/Contact/addContact";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import ContactDataService from '@/services/ContactDataService';
import OrganizationDataService from "@/services/OrganizationDataService";
import i18n from '/common/plugins/vue-i18n.js' 

export default Vue.extend({
  props: {
    contactData: {
      type: Array,
      required: true,
    },
    upchatContact: {
      type: Object,
      required: true,
    },
  },
  components: {
    addContact,
    successDialog,
    messageDialog,
    errorDialog,
  },
  created() {
    this.contactData.forEach((data) => {
      if(data.first_name != null)
        data.full_name = data.last_name +" "+ data.first_name;
      else
        data.full_name = data.last_name;
        
      if (data.organization_set.length == 0) {
        data.accountName = "";
        data.accountId = ""
      } else {
        data.accountName = data.organization_set[0].name;
        data.accountId = data.organization_set[0].id;
      }
    });    
  },
  data() {
    return {
      valid: true,
      nameRules: [(v) => !!v || i18n.t('RULE.RULE_R_LASTNAME')],
      searchMainContact: "",
      mainContactHeader: [
        {
          text: i18n.t('RELATEDCONTACTS.CONTACT_NAME'),
          value: "full_name",
          align: "center",
          filterable: true,
          width: '20%',
        },
        {
          text: i18n.t('RELATEDCONTACTS.PHONE'),
          value: "mobile_phone",
          align: "center",
          filterable: false,
          width: '20%',
        },
        {
          text: i18n.t('RELATEDCONTACTS.MAIL'),
          value: "email",
          align: "center",
          filterable: false,
          width: '20%',
        },
        {
          text: i18n.t('RELATEDCONTACTS.ORG_NAME'),
          value: "accountName",
          align: "center",
          filterable: true,
          width: '20%',
        },        
      ],
      mainContactPage: 1,
      mainContactPageCount: 0,
      mainContactItemPerPage: 8,
      addContactDialog: false,
      successDialog: false,
      messageDialog: false,
      errorDialog: false,
      message: "",
      isNewContact: false,
      isDetailReadonly: true,
      addContactList: [],
      addContactData: null,
      newContact: {},
      search: '',
      errorMessage: '',
      searchText: '',
      contact: null,
      combo_loading: false,
      organizationList:[],
      org_data:{},
      syncContact:false,
      addTabId:null,
      addTabName:null,
      add_new_contact:false,
    };
  },
  methods: {
    // NOOTNOOT(){
    //   let obj = window.localStorage.getItem('UpChatList')
    //   if(obj)
    //   {
    //     this.UpChatList = JSON.parse(obj)
    //   }
    //   console.log(this.UpChatList)
    // },
    async addContact(){
        this.add_new_contact = true;
        this.organizationList = []
        this.org_data ={}
        if(this.upchatContact.additional_attributes.company_name != ''){
            await this.get_org(this.upchatContact.additional_attributes.company_name)
        }else{
          this.org_data = null
        }
        const nameArray = this.upchatContact.name.split(" ");
        let first_name = null
        let last_name = null
        if(nameArray.length>1){
            first_name = nameArray.slice(0,nameArray.length - 1).join(" ")
            last_name = nameArray[nameArray.length - 1]
        }else{
            first_name = null
            last_name = this.upchatContact.name
        }

        let addContact = {
            "custom_field" : null,
            "email": this.upchatContact.email != ''? this.upchatContact.email:null,
            "first_name": first_name,
            "instagram_id": null,
            "id":null,
            "last_name": last_name,
            "level": null,
            "line_id": null,
            "facebook":this.upchatContact.additional_attributes.social_profiles.facebook !='' ? this.upchatContact.additional_attributes.social_profiles.facebook:null,
            "linkedin":this.upchatContact.additional_attributes.social_profiles.linkedin !='' ? this.upchatContact.additional_attributes.social_profiles.linkedin:null,
            "mobile_phone": this.upchatContact.phone_number != ''? this.upchatContact.phone_number:null,
            "office_phone": null,
            "organization_set":this.org_data != null? [this.org_data]:[],
            "description": this.upchatContact.additional_attributes.description !=''? this.upchatContact.additional_attributes.description:null,
        }      
      await ContactDataService.create(addContact,this.upchatContact.id)
        .then(response => {
              let full_name = response.data.first_name? response.data.last_name +" "+ response.data.first_name:response.data.last_name;
              this.addTab(full_name,response.data.id,'Contacts',true);
        });
    },
    async get_org(name){
        await OrganizationDataService.findOrganizationByNamefromDB(name)
        .then((response) => {
            this.organizationList = response.data;
            if(this.organizationList.length>0){
            this.org_data={
                "company_number":this.organizationList[0].company_number,
                "id":this.organizationList[0].id,
                "name":name
            }
            }
            if(this.organizationList.length==0){
                this.org_data={
                    "company_number":"0",
                    "id": "-1",
                    "name":name
                }
            }
        })
    },
    addTab(name,id,page,add){
      if(page == "Contacts"){
        this.syncContact = true;
        this.addTabId = id;
        this.addTabName = name;
        if(add){
          this.$emit('emitupchatContactDialog',this.addTabName,this.addTabId,"Contacts",true);
          this.addTabId = null;
          this.addTabName = null;
          this.syncContact = false;
        }else{
          this.showMessageDialog(i18n.t("UPCHAT.SYNC"));
        }
      }
      else{
        this.$emit('emitupchatContactDialog',name,id,page);
      }
    },
    validateForm() {
        if (this.$refs.form.validate()){
            if(this.isNewContact){
                return true;
            }
            else{
                if(typeof(this.contact) == 'object')
                    return true;
                else
                    return false;
            }
        } 
        else {
            return false;
        }
    },
    onCancel() {
      this.$emit('emitupchatContactDialog',false);
    },
    // Common Function
    onCall: function (cell) {
      return "tel:" + cell;
    },
    onMail: function (mail) {
      return "mailto:" + mail;
    },
    showSussessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showAddContact() {
        this.isDetailReadonly = false;
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },
    onEmitMessageDialog(val) {
      if(val && this.syncContact){
        this.$emit('emitupchatContactDialog',this.addTabName,this.addTabId,"Contacts",true);
      }
      this.addTabId = null;
      this.addTabName = null;
      this.syncContact = false;
      this.messageDialog = false;
      
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
  },
  watch: {
  }
});
</script>
<style>
    .add-deal-btn-contact {
        width: 200px !important;
        height: 50px !important;    
    }
    .v-btn-save {  
        float: right; 
    }
    .t-primary {
        color: #518bf2 !important;
    }
</style>
