<template lang='pug'>
    iframe(id="upchat_vframe" class="vframe" title="UpChat" src="")
</template>

<script>
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            hasUpchatLogin: false,
            isUpchatUser: false,
            userJson: JSON.parse(window.localStorage.getItem('user')),
        };
    },
    created() {
        if (window.localStorage.getItem('isUpchatUser') != null)
            this.isUpchatUser = window.localStorage.getItem('isUpchatUser') === 'true' ? true : false;
        if (window.localStorage.getItem('upchat_login') != null)
            this.hasUpchatLogin = window.localStorage.getItem('upchat_login') === 'true' ? true : false;
    },
    // mounted() {
    //     if(this.isUpchatUser){
    //         if (!this.hasUpchatLogin && this.userJson.upchat_sso_link != '') {
    //             const upchatIframe = document.getElementById('upchat_vframe');
    //             upchatIframe.src = this.userJson.upchat_sso_link;
    //             window.localStorage.setItem('upchat_login', true);
    //         }
    //     }
    // }
});
</script>